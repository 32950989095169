var map;

function submit_kapcsolat(token) {
	document.getElementById('kapcsolat_form').submit();
}

jQuery(function(){
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$('.swal').each(function(){
		swal({
			text: $(this).html(),
			icon: $(this).data('swal-icon') ? $(this).data('swal-icon') : ''
		});
	});

	var menuClose = function(e){
		if(!$(e.target).parents('.menu.open').length && !$(e.target).hasClass('menu-toggle')){
			e.preventDefault();
			$('.menu.open,.menu-toggle.open').removeClass('open');
			$(document).off('click', menuClose);
		}
	};

	$(".menu-toggle").on("click", function(e){
		e.preventDefault();
		e.stopImmediatePropagation();
		$(".menu, .menu-toggle").toggleClass("open");
		if($(".menu.open").length){
			$(document).on('click', menuClose);
		}
	});

	new WOW().init();

	$('.fooldal-hero-nyil').on('click', function(e){
		e.preventDefault();
		console.log(e)

		$('html, body').animate({
			scrollTop: $(".header").height() + $(".fooldal-hero").height()
		}, 600);
	});
});

// ----- ajax-os delegálások -----
/*
$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});
*/
$(document).on('change blur', 'form.auto :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();

	var $this = $(this);
	$this.find('button[type=submit]').html('<span class="glyphicon glyphicon-refresh rotating"></span>');
	$this.find('input,select,textarea').attr('disabled', true);

	$.ajax({
		url: $this.attr('action'),
		method: $this.attr('method'),
		data: $this.serialize(),
		complete: function (resp) {
			var $submit_btn = $this.find('button[type=submit]');
			if($submit_btn.data('value')) {
				$submit_btn.html($submit_btn.data('value'));
			}
			$this.find('input,select,textarea').attr('disabled', false);
		},
		success: function (resp) {
			$elem.find('.has-error').each(function () {
				$(this).removeClass('has-error').find('span.help-block').remove();
			});

			if (resp.status == 'success') {
				swal(resp.uzenet);
			} else if (resp.status == 'error') {
				swal(resp);
				/*
				 $.each(resp.errors, function (key, value) {
				 $elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value.join('<br>') + '</span>');
				 });
				 */
			}
		}
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	kapcsolat: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			swal(resp.uzenet);
		} else if (resp.status == 'error') {
			swal(resp);
			/*
			$.each(resp.errors, function (key, value) {
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value.join('<br>') + '</span>');
			});
			*/
		}
	},
};
